import axios from '../libs/axios';

// 订单统计
export const reportOrder = (data) => {
  return axios.get('/api/trd/report/order', data);
};

// 七日订单
export const reportOrder7d = (data) => {
  return axios.get('/api/trd/report/order7d', data);
};

// 订单明细
export const reportSerials = (data) => {
  return axios.get('/api/trd/report/serials', data);
};

// 订单导出
export const reportSerialsExport = (data) => {
  return axios.get('/api/trd/report/serials_export', data);
};


// 营销统计
export const reportDayamt = (data) => {
  return axios.get('/api/trd/statis/dayamt', data);
};

export const reportDaycnt = (data) => {
  return axios.get('/api/trd/statis/daycnt', data);
};

export const reportInfo = (data) => {
  return axios.get('/api/trd/statis/info', data);
};

export const reportPaymethod = (data) => {
  return axios.get('/api/trd/statis/paymethod', data);
};

export const reportShop = (data) => {
  return axios.get('/api/trd/statis/shop', data);
};
